<template>
    <div>
        <div class="card-titulo d-flex justify-content-between align-items-center">
            <button type="button" class="btn btn-sm" aria-label="Left Align"  @click.prevent.stop="$router.go(-1)">
                <span class="fa fa-chevron-left fa-lg" aria-hidden="true"></span> Voltar </button>
            <h1>CONTEÚDOS ONLINE</h1>
            <i></i>
        </div>
        <div class="cards card-horario mb-2">
            <div>
                <h2>{{matricula.turma.turm_identificacao}}</h2>
                <h1 v-if="infoEscola.esc_tipo === 1">{{matricula.turma.serie.ser_descricao}}</h1>
                <h1>{{matricula.turma.curso.cur_nome}}</h1>
            </div>
            <div class="d-flex flex-row justify-content-between">
                <div class="d-flex flex-row">
                    <div class="d-flex flex-column">
                        <small>Matrícula</small>
                        <span>{{matricula.matri_matricula}}</span>
                    </div>
                </div>
            </div>
        </div> 

        <div class="cards card-horario d-flex flex-column">
            <div class="cards-titulo d-flex flex-row justify-content-start align-items-center m-2">
                <i class="fa fa-television text-dark mr-2" aria-hidden="true"></i>
                <h1 class="text-dark m-0 p-0">Vídeos / Áudios</h1>
            </div>
            <div class="d-flex flex-row justify-content-start">
                <template v-for="(video,idx) in videos">
                    <div class="grid-conteudo d-flex flex-column justify-content-start align-items-center text-center m-2" :key="idx">
                        <span class="text-dark my-1 font-weight-bold">{{video.contdm_descricao}}</span>
                        <i class="fa fa-5x text-muted my-2" :class="video.contdm_tipo === 1 ? 'fa-television' : video.contdm_tipo === 2 ? 'fa-volume-up' : ''"></i>
                        <span class="text-secondary my-1">{{video.contdm_data | data}}</span>
                        <span class="text-muted font-weight-bold">{{video.disciplina.disp_nome}}</span>
                        <small class="text-info">{{video.professor.pes_nome}}</small>
                        <small class="text-muted my-1">{{video.etapa.etap_descricao}}</small>
                        <button type="button" class="btn btn-sm btn-danger w-100 text-white my-2" aria-label="Left Align"  @click.prevent.stop="onAbrirVideo(video.contdm_url)">
                            <span class="fa fa-play fa-lg text-white mr-2" aria-hidden="true"></span>Assistir Vídeo </button>
                    </div>
                </template>
            </div>
        </div>

        <div class="cards card-horario d-flex flex-column">
            <div class="cards-titulo d-flex flex-row justify-content-start align-items-center m-2">
                <i class="fa fa-file-o text-dark mr-2" aria-hidden="true"></i>
                <h1 class="text-dark m-0 p-0">Arquivos PDFs/Imagens</h1>
            </div>
            <div class="d-flex flex-row justify-content-start">
                <template v-for="(arquivo,idx) in arquivos">
                    <div class="grid-conteudo d-flex flex-column justify-content-start align-items-center text-center m-2" :key="idx">
                        <span class="text-dark my-1 font-weight-bold">{{arquivo.contdm_descricao}}</span>
                        <i class="fa fa-5x text-muted my-2" :class="arquivo.contdm_tipo === 3 ? 'fa-file-pdf-o' : arquivo.contdm_tipo === 4 ? 'fa-file-image-o' : ''"></i>
                        <span class="text-secondary my-1">{{arquivo.contdm_data | data}}</span>
                        <span class="text-muted font-weight-bold">{{arquivo.disciplina.disp_nome}}</span>
                        <small class="text-info">{{arquivo.professor.pes_nome}}</small>
                        <small class="text-muted my-1">{{arquivo.etapa.etap_descricao}}</small>
                        <button type="button" class="btn btn-sm btn-info w-100 text-white my-2" aria-label="Left Align"  @click.prevent.stop="onDownloadItem(arquivo.contdm_arquivo_url, arquivo.contdm_arquivo)">
                            <span class="fa fa-download fa-lg text-white mr-2" aria-hidden="true"></span>Download Arquivo </button>
                    </div>
                </template>
            </div>
        </div>

        <b-modal id="modal-video" centered hide-header hide-footer>
            <b-embed
                type="iframe"
                aspect="16by9"
                :src="srvVideo"
                allowfullscreen
            ></b-embed>

            <!--<video id="meuVideo" :src="srvVideo"/>-->
            <p class="text-center m-3">
                <a :href="srvVideo" target="_blank">Houve algum problema ao reproduzir o vídeo? Clique aqui para abri-lo no seu local de origem.</a>
            </p>
            <!--<iframe 
                :src="srvVideo" 
                title="YouTube video player" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen>
            </iframe>           -->
        </b-modal>
            
        
    </div>
</template>

<script>
import axios from '@/http'
import download from 'axios'
import { mapState } from 'vuex'
import moment from 'moment'

export default {
    Name: 'PortalConteudo',
    components: {
        
    },
    data () {
        return {
            conteudo: [],
            srvVideo: null
        }
    },
    methods: {
        async getConteudo () {
            await axios.get('/conteudo',
            {
                params: {
                    entidade: this.$route.params.entidade,
                    escola: this.$route.params.escola,
                    anoletivo: this.$route.params.ano_letivo,
                    turma: this.$route.params.turma
                }
            })
            .then(response => {
                this.conteudo = response.data.data;  
            })
        },
        onAbrirVideo (video) {
            this.srvVideo = video;
            this.$bvModal.show('modal-video');
        },
        onDownloadItem ( url, label ) {
            download.get(url, { responseType: 'blob' })
            .then(response => {
                const blob = new Blob([response.data], { type: 'application/pdf' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = label
                link.click()
                URL.revokeObjectURL(link.href)
            }).catch(console.error)
        }

    },
    mounted () {
        this.getConteudo();    
    },
    computed: {
        ...mapState('Portal',['matriculas','infoEscola']),
        matricula () {
            return this.matriculas.filter(item => item.turma.turm_codigo == this.$route.params.turma)[0];
        },
        videos () {
            return this.conteudo.filter(item => item.contdm_tipo === 1 || item.contdm_tipo === 2);
        },
        arquivos () {
            return this.conteudo.filter(item => item.contdm_tipo === 3 || item.contdm_tipo === 4);
        }
    }
}

/*

https://github.com/kaorun343/vue-youtube-embed
 */
</script>


<style scoped>
.grid-conteudo {
    background-color: var(--gray-100);
    border: 1px solid #FFFFFF;
    padding: 20px;
    border-radius: 4px;
    width: 230px;
    height: 300px;
}

.grid-conteudo > span {
    color: #05512C;
    font-weight: 500;
}

.cards-titulo > h1 {
    font-size: 1rem!important;
    font-weight: 600;
}
</style>

